<template>
  <div class="page">
    <div class="page__header" style="margin-bottom: 25px">
      <h1 style="margin-top: 10px">Collection</h1>
      <div class="page__header-search">
        <BaseSelect
          class="form-control--grey form-control--xs"
          placeholder="Search by"
          :selected="searchType"
          :items="searchTypes"
          @action="searchingType"
        ></BaseSelect>
        <BaseInput
          class="form-control--grey form-control--xs"
          style="max-width: 65rem"
          placeholder="Search"
          :icon="true"
          :timeout="true"
          :element="tableData.search"
          @input="searchItems"
        >
          <template #icon>
            <IconSearch color="#000" height="16" width="16"></IconSearch>
          </template>
        </BaseInput>
      </div>
    </div>
    <div class="page__header">
      <HorisontalTabsList :tabs="tabs"></HorisontalTabsList>
      <div class="page__header_right">
        <FilterBlock
          :items="filters"
          @open="openFilters = $event"
          @selectFilter="selectFilter"
          @apply="refreshSelectedFilters"
          @clear="refreshSelectedFilters(true)"
        ></FilterBlock>
        <IconButton class="button--auto button--uppercase" text="add art" @click="modal = true">
          <IconPlusBold></IconPlusBold>
        </IconButton>
      </div>
    </div>
    <div class="page__filters">
      <template v-for="filter in selectedFilters"
        ><Tag
          class="tag--capitalize"
          :class="{ 'tag--selected': i.selected }"
          v-for="i in filter.items"
          :key="i.title"
          :value="i.title"
          :close="true"
          :disabled="openFilters"
          @deleteTag="selectFilter({ key: filter.key, filter: i, apply: true })"
        ></Tag
      ></template>
    </div>
    <Table
      :loading="loading"
      :items="items"
      :headerItems="headerItems"
      :tableData="tableData"
      :hasMorePages="hasMorePages"
      @sortTable="sortTable"
      @clickAction="clickAction"
      @loadMore="loadMore"
    ></Table>
    <Art
      v-if="modal"
      :modal="modal"
      :item="art"
      @hideModal="modal = false"
      @createArt="createArt"
      @updateArt="updateArt"
      @removeArt="confirmeRemoveArt"
      @updateTag="apiGetFiltersBlock(filterBlock)"
    ></Art>
  </div>
</template>
<script>
import HorisontalTabsList from "@/components/HorisontalTabsList.vue";
import FilterBlock from "@/components/Filter.vue";
import Tag from "@/components/Tag.vue";
import IconButton from "@/components/buttons/IconButton.vue";
import IconPlusBold from "@/components/icons/IconPlusBold.vue";
import Table from "@/components/Table.vue";
import { mapMutations, mapGetters, mapActions } from "vuex";
import Art from "./Art.vue";

import IconSearch from "@/components/icons/IconSearch.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseSelect from "@/components/inputs/BaseSelect.vue";

export default {
  components: {
    HorisontalTabsList,
    FilterBlock,
    IconButton,
    IconPlusBold,
    Table,
    Tag,
    Art,
    BaseInput,
    BaseSelect,
    IconSearch,
  },
  data() {
    return {
      tabs: [
        {
          title: "Artwork",
          path: "/admin/collection/artworks",
          key: "artwork",
        },
        {
          title: "Creators",
          path: "/admin/collection/creators",
          key: "artists",
        },
        {
          title: "Categories",
          path: "/admin/collection/categories",
          key: "categories",
        },
        {
          title: "Viewing Rooms",
          path: "/admin/collection/viewing-rooms",
          key: "viewing-rooms",
        },
      ],
      modal: false,
      filterBlock: {
        list: "categories,tags,statuses",
        type: "collection",
      },
      openFilters: false,
      tableData: {
        page: 1,
        sort: "title",
        order: "asc",
        status: "",
        tags: "",
        categories: "",
        search: "",
        search_type: "",
      },
      searchTypes: [
        {
          key: "title",
          title: "Title",
        },
        {
          key: "artist_name",
          title: "Creator",
        },
      ],
      searchType: "title",
    };
  },
  async created() {
    await this.apiGetFiltersBlock(this.filterBlock);
    await this.apiGetArtworks(this.tableData);
    this.initialArt();
  },
  computed: {
    ...mapGetters("filters", {
      filters: "getFilters",
      selectedFilters: "getSelectedFilters",
    }),
    ...mapGetters("artworks", {
      items: "getArtworks",
      headerItems: "getArtworksHeaders",
      art: "getArt",
      loading: "getLoading",
      hasMorePages: "getHasMorePages",
      collection: "getCollection",
    }),
  },
  watch: {
    modal(val) {
      if (!val) {
        this.initialArt();
        this.setError(null);
      }
    },
  },
  methods: {
    ...mapActions("general", ["apiDeleteUploadImage"]),
    ...mapActions("filters", ["apiGetFiltersBlock"]),
    ...mapMutations("filters", ["updateFilters", "updateSelectedFilters", "clear"]),

    ...mapActions("artworks", [
      "apiGetArtworks",
      "apiGetArt",
      "apiPostArt",
      "apiPutArt",
      "apiDeleteArt",
    ]),
    ...mapMutations("artworks", ["initialArt", "setError"]),
    selectFilter(val) {
      this.updateFilters(val);
      if (val.apply) {
        this.refreshSelectedFilters();
      }
    },
    async sortTable({ sort, order }) {
      let data = { ...this.tableData, page: 1, sort, order };
      let success = await this.apiGetArtworks(data);
      if (success) {
        this.tableData = data;
      }
    },
    async loadMore() {
      if (this.hasMorePages) {
        let data = { ...this.tableData, page: this.tableData.page + 1 };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    searchingType(value) {
      this.searchType = value.key;
      this.searchItems(this.tableData.search);
    },
    async searchItems(val) {
      if (this.tableData.search != val || this.tableData.search_type != this.searchType) {
        let data = { ...this.tableData, page: 1, search: val, search_type: this.searchType };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
      }
    },
    async refreshSelectedFilters(clear) {
      if (clear) {
        await this.clear();
      }
      await this.updateSelectedFilters();
      this.filterTable();
    },
    async filterTable() {
      let data = { ...this.tableData, page: 1 };
      this.selectedFilters.forEach(el => {
        let filters = el.items.map(i => {
          return i.id;
        });
        data[el.key] = filters.join(",");
      });
      let success = await this.apiGetArtworks(data);
      if (success) {
        this.tableData = data;
      }
    },
    async editArt({ id }) {
      let result = await this.apiGetArt(id);
      if (result) {
        this.modal = true;
      }
    },

    async clickAction({ key, id, item }) {
      switch (key) {
        case "edit": {
          let result = await this.apiGetArt(id);
          if (result) {
            this.modal = true;
          }
          break;
        }
        case "delete": {
          let result = await this.confirmeRemoveArt(item);
          if (result) {
            this.modal = true;
          }
          break;
        }
      }
    },

    async createArt({ data, imageForRemove }) {
      let result = await this.apiPostArt(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Artwork successfully created",
          confirmButtonText: "Ok",
        });
        /* if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        } */
      }
    },
    async updateArt({ data, imageForRemove }) {
      let result = await this.apiPutArt(data);
      if (result) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$swal({
          title: "Artwork successfully updated",
          confirmButtonText: "Ok",
        });
        /* if (imageForRemove.length) {
          this.apiDeleteUploadImage(imageForRemove);
        } */
      }
    },
    confirmeRemoveArt(item) {
      this.$swal({
        title: `Delete ${item.title}`,
        html: "<h5>Are you sure? <br/> This cannot be undone.",
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: "Delete",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.value === false) {
          this.removeArt(item.id);
        }
      });
    },
    async removeArt(id) {
      let result = await this.apiDeleteArt(id);
      if (result.success) {
        let data = { ...this.tableData, page: 1 };
        let success = await this.apiGetArtworks(data);
        if (success) {
          this.tableData = data;
        }
        this.modal = false;
        this.$toasted.show(result.message, {
          duration: 2000,
          type: "error",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
    &_right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .button {
        margin: 0 0 0 2rem;
      }
    }
    &-search {
      display: flex;
      align-items: center;
      width: 65rem;
      gap: 0 10px;
    }
  }
  &__filters {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 7px;
    .tag {
      margin-left: 1rem;
    }
  }
}
</style>

